import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import Seo from '../../components/SEO/seo';
import Layout from '../../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Gebruiksvoorwaarden",
  "path": "/nl/terms-of-use"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Gebruiksvoorwaarden`}</h1>
    <p>{`Leuk dat je Flow wilt gebruiken! We vinden het belangrijk dat je op de hoogte bent van onze gebruiksvoorwaarden. Neem ze goed door voordat je onze app gebruikt. Heb je er vragen over? Je kunt ons bereiken op `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}>{`hi@flowyour.money`}</a></p>
    <h2>{`1. Over ons, Onze Diensten en Onze Voorwaarden`}</h2>
    <p>{`Je bent natuurlijk benieuwd wat de voorwaarden zijn voor het gebruik van Flow. Wij willen hier graag duidelijk en transparant in zijn. Om te beginnen: wij zijn Flow Money Automation B.V., een Nederlandse onderneming die zich tot doel heeft gesteld om jou te helpen grip op je financiële zaken te krijgen. Om dit doel te bereiken leveren wij de volgende diensten (hierna: “Onze Diensten”):`}</p>
    <ul>
      <li parentName="ul">{`Onze website `}<a parentName="li" {...{
          "href": "https://flowyour.money"
        }}>{`https://flowyour.money`}</a>{` (de “Site”);`}</li>
      <li parentName="ul">{`De Flow mobiele app (de “App”); en`}</li>
      <li parentName="ul">{`Alle overige diensten die beschikbaar zijn door of via de App of de Site;`}</li>
      <li parentName="ul">{`Alle opvolgende versies en updates van de Site, de App of andere software, of eventuele nieuwe websites.`}</li>
    </ul>
    <p>{`Onze Gebruiksvoorwaarden en de Privacy Verklaring, (samen “Onze Voorwaarden”) zijn van toepassing op Onze Diensten. Met andere woorden: als je gebruikt maakt van Onze Diensten (dus van de Site of de App, etc.), ga je daarmee automatisch akkoord met Onze Voorwaarden. Je bent verplicht onze voorwaarden goed door te lezen en het is niet toegestaan gebruik te maken van Onze Diensten als je hiermee niet 100% akkoord bent. De toepasselijkheid van andere voorwaarden wordt uitdrukkelijk van de hand gewezen.`}</p>
    <p>{`Voor als je ons wilt bereiken heb je hier onze gegevens:`}</p>
    <p>{`Flow Money Automation B.V
Riperwei 54
8406 AK Tijnje
Kamer van Koophandel: 72829796
E-mail: `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}>{`hi@flowyour.money`}</a></p>
    <p>{`Flow heeft een zogenaamde ‘PSD2-licentie’. Dat is een vergunning van De Nederlandsche Bank waarmee we onze betalingsdiensten kunnen aanbieden. PSD2 staat voor ‘Payment Services Directive 2’ en is richtlijn die ervoor zorgt dat Europese consumenten hun bank kunnen vragen om gegevens te delen met bedrijven zoals Flow, op een veilige en verantwoorde manier. Flow heeft sinds 13 juli 2020 een vergunning om je rekeninginformatie in te zien (Dienst 8) en om betaalinistiaties uit te voeren (Dienst 7). Je kunt ons vinden in het DNB Register betaalinstellingen onder het nummer: R166735`}</p>
    <p>{`Daarnaast is Flow ISO 27001:2013 gecertificeerd. Dit hebben we gedaan om jouw privacy te waarborgen. Op deze manier kunnen wij aantonen dat informatiebeveiliging bij Flow een belangrijk element is. Jaarlijks worden wij daarop beoordeeld door externe professionele auditors die ons scherp houden. Je kunt `}<a parentName="p" {...{
        "href": "/ISO-IEC_27001-ENG-10000319441-MSC-UKAS-NLD-3-20241129.pdf"
      }}>{`ons certificaat hier vinden`}</a>{`.`}</p>
    <p>{`Wij behouden ons het recht Onze Voorwaarden eenzijdig aan te passen. Het kan zijn dat je Onze Diensten niet kunt gebruiken totdat je de laatste versie van Onze Voorwaarden hebt geaccepteerd. Wanneer er wijzigingen zijn aan de Gebruiksvoorwaarden dan stellen we je daarvan op de hoogte. Deze Gebruiksvoorwaarden zijn gepubliceerd op 20 augustus 2020, maar de nieuwste versie is steeds te bekijken via: `}<a parentName="p" {...{
        "href": "https://flowyour.money/terms"
      }}>{`https://flowyour.money/terms`}</a></p>
    <h2>{`2. Over jou en je account`}</h2>
    <p>{`Dan over jou. Je bent onze klant, dat betekent dat je 18 jaar of ouder bent en verder volledig beschikkingsbevoegd en handelingsbekwaam. Ben je dat (nog) niet dan zal je nog even moeten wachten: je kunt dan niet Onze Diensten gebruiken.`}</p>
    <p>{`Om gebruik te maken van (een deel van) Onze Diensten heb je een account nodig. Hiervoor gelden de volgende regels:`}</p>
    <ol>
      <li parentName="ol">{`Je bent verplicht je te registreren onder jouw eigen echte- en volledige naam en ook verder alle informatie juist en volledig aan ons door te geven;`}</li>
      <li parentName="ol">{`Als er iets wijzigt in jouw situatie, waardoor gegevens van jouw account niet meer kloppen, ben je verplicht dit direct aan ons door te geven. Dit kan via `}<a parentName="li" {...{
          "href": "mailto:hi@flowyour.money"
        }}>{`hi@flowyour.money`}</a>{`;`}</li>
      <li parentName="ol">{`Het is niet toegestaan om anderen gebruik te laten maken van jouw account;`}</li>
      <li parentName="ol">{`Je mag alleen mensen die officieel gemachtigd zijn om jouw account te gebruiken toegang geven tot Flow;`}</li>
      <li parentName="ol">{`Het is niet toegestaan om bankrekeningen aan jouw account te koppelen die niet van jou zijn;`}</li>
      <li parentName="ol">{`Het is niet toegestaan een account voor een ander te openen. Met uitzondering van bedrijven die geautoriseerd zijn door hun opdrachtgevers om een account te openen en Flow te gebruiken.;`}</li>
      <li parentName="ol">{`Het automatisch aanmaken van accounts, bijvoorbeeld door een script, bot of een geautomatiseerd proces, is strikt verboden; en`}</li>
      <li parentName="ol">{`Het (al dan niet commercieel) uitbaten van Onze Diensten ten behoeve van derden is verboden.`}</li>
    </ol>
    <p>{`Je staat er voor in en bent er volledig verantwoordelijk voor dat alle gegevens die je aan ons verstrekt correct en actueel zijn en dat we jou kunnen bereiken op de door jou opgegeven contactgegevens. Je vrijwaart ons voor (potentiële) schade die het gevolg is van een overtreding door jou van dit artikel.`}</p>
    <p>{`In principe is natuurlijk iedereen (ouder dan 18 jaar etc.) welkom als klant, maar als wij denken dat je Onze Diensten niet gebruikt zoals ze bedoeld zijn (bijvoorbeeld als je handelt in strijd met deze Gebruiksvoorwaarden), dan hebben wij het eenzijdige recht onze samenwerking te beëindigen. Dit betekent onder andere dat wij het recht hebben om per direct toegang tot Onze Diensten (en dus tot jouw account) te blokkeren en dat wij eventuele data en (persoonlijke) gegevens kunnen verwijderen. Wij zijn niet verantwoordelijk voor de eventuele gevolgen daarvan.`}</p>
    <p>{`Zo hoort bij het gebruiken van Onze Diensten dat wij controleren of de door jou opgegeven identiteit klopt en je bijvoorbeeld niet op PEP- of sanctielijsten wordt vermeld (PEP’s zijn politieke prominente personen). Ook controleren wij of je niet aan witwassen- en of terrorismefinanciering doet. Wanneer wij deze controle niet goed kunnen uitvoeren omdat je ons niet van de juiste gegevens voorziet of dat uit de controles blijkt dat je wij de toegang moeten weigeren, dan kan het zijn dat we de samenwerking beëindigen.`}</p>
    <p>{`Onze Diensten zijn niet ontwikkeld om aan jouw individuele omstandigheden te voldoen. Het is jouw eigen verantwoordelijkheid om goed te kijken of Onze Diensten bij jou passen en je verder kunnen helpen.`}</p>
    <h2>{`3. Beveiliging van jouw account`}</h2>
    <p>{`Je bent verplicht alles te doen om je account te beveiligen, waaronder in ieder geval het volgende:`}</p>
    <ol>
      <li parentName="ol">{`Het apparaat waarmee je toegang hebt tot Onze Diensten, zoals mobiele telefoon, tablet, laptop of ander apparaat (hierna: het “Apparaat”) gebruik je persoonlijk en houd je in de gaten wanneer je dit uitleent. Je laat in geen geval anderen gebruik maken van Onze Diensten op jouw Apparaat;`}</li>
      <li parentName="ol">{`Het Apparaat is beveiligd met een pincode of wachtwoord en je zorgt ervoor dat deze beveiliging afdoende is, niet bij anderen bekend is en niet door anderen gezien kan worden;`}</li>
      <li parentName="ol">{`Wijzig je wachtwoord of pin regelmatig;`}</li>
      <li parentName="ol">{`Je zult je wachtwoord en inloggegevens van je account veilig bewaren en niet delen met derden;`}</li>
      <li parentName="ol">{`Je zult de e-mail accounts geassocieerd met Flow beveiligd houden;`}</li>
      <li parentName="ol">{`Wanneer je PIN code gestolen is of kwijt geraakt, of iemand heeft toegang tot je e-mail account zul je direct met ons contact opnemen zodat wij maatregelen kunnen treffen. Je kunt ons in dat geval altijd direct bereiken via `}<a parentName="li" {...{
          "href": "mailto:hi@flowyour.money"
        }}>{`hi@flowyour.money`}</a>{`;`}</li>
      <li parentName="ol">{`Het Apparaat dat je gebruikt dient voorzien te zijn van de laatste eisen qua veiligheid en prestaties. Je bent verplicht altijd de laatste (veiligheids)updates te downloaden en installeren; en`}</li>
      <li parentName="ol">{`Wij zullen nooit om wachtwoorden of pincodes vragen. Je zult deze gegevens dus nooit verstrekken als hierin per e-mail, telefonisch, per sms, of anderszins wordt gevraagd.`}</li>
    </ol>
    <p>{`Jij zorgt ervoor dat je bovenstaande veiligheidsmaatregelen in acht neemt en vrijwaart ons voor schade als gevolg van het overtreden daarvan. Houd je je toch niet aan de veiligheidseisen en volgen er problemen dan zijn wij hiervoor in ieder geval niet aansprakelijk.`}</p>
    <h2>{`4. Beschikbaarheid van Onze Diensten`}</h2>
    <p>{`Wij zullen ons best doen om Onze Diensten te allen tijde beschikbaar te hebben, maar kunnen niet garanderen dat dit altijd lukt. Ten eerste zijn wij natuurlijk afhankelijk van de banken met wie wij samenwerken. Als het een keer fout gaat, hoeft het dus niet aan ons te liggen. Daarnaast kunnen wij natuurlijk altijd te maken krijgen met onverwachte situaties of storingen. Voor schade die ontstaat doordat Onze Diensten niet bereikbaar zijn, zijn wij niet aansprakelijk. Wij zijn sowieso niet aansprakelijk voor schade door problemen die optreden als gevolg van gebrekkige software, hardware, of netwerkverbindingen van het Apparaat.`}</p>
    <p>{`Wij willen Onze Diensten blijven verbeteren. Daarom hebben wij het recht wijzigingen in Onze Diensten (zoals onze software) aan te brengen en zaken aan te passen. Wij hebben daarom ook het recht Onze Diensten (tijdelijk) buiten gebruik te stellen of te beperken in het geval dit bijvoorbeeld noodzakelijk is voor het onderhouden of het aanpassen daarvan, zonder dat hierdoor enig recht op schadevergoeding of compensatie ontstaat.`}</p>
    <p>{`Wij hebben de inhoud van Onze Diensten (waaronder bijvoorbeeld de teksten op de Website of in de App) met de grootst mogelijke zorg samengesteld, maar met uitsluiting van eventuele uitdrukkelijke of stilzwijgende garanties, toezeggingen of vrijwaringen van welke aard dan ook. Wij kunnen niet instaan voor de relevantie, betrouwbaarheid en juistheid van de inhoud van de getoonde informatie. Onder meer omdat veel informatie afkomstig is van de (software van) banken waarmee wij samenwerken. Van jou wordt vertrouwelijkheid en oplettendheid verwacht. Zie je ergens een fout in een tekst, of heb je een vermoeden van een veiligheidsfout dan ben je verplicht dit aan ons te melden via `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}>{`hi@flowyour.money`}</a>{`.`}</p>
    <h2>{`5. Verbod op misbruik van Onze Diensten`}</h2>
    <p>{`Alle teksten, plaatjes, programmacode, etc. die onderdeel zijn van Onze Diensten, ongeacht of wij hierop enig recht van intellectueel eigendom hebben, noemen wij gezamenlijk “Onze Content”. Het is verboden Onze Content op welke wijze ook aan derde(n) beschikbaar te stellen, te reproduceren, verspreiden, wijzigen, verzenden of op welke wijze dan ook in ander materiaal te verwerken danwel anderszins te gebruiken anders dan persoonlijk en conform het doel van Onze Diensten. Daarnaast is het strikt verboden:`}</p>
    <ol>
      <li parentName="ol">{`Onze Diensten in strijd met doel of strekking van de wet of deze Gebruiksvoorwaarden te gebruiken;`}</li>
      <li parentName="ol">{`Inbreuk te maken op intellectueel eigendom;`}</li>
      <li parentName="ol">{`Diensten aan te bieden die verwart zouden kunnen worden met Onze Diensten;`}</li>
      <li parentName="ol">{`Onze Diensten zo te gebruiken dat schade of overlast kan ontstaan;`}</li>
      <li parentName="ol">{`Een poging te doen om gegevens te ontcijferen of te onderscheppen (bijvoorbeeld door de encryptie te breken); of`}</li>
      <li parentName="ol">{`Een poging te doen om de inhoud van Onze Diensten te reproduceren of aan te passen.`}</li>
    </ol>
    <h2>{`6. Rechten en licentie`}</h2>
    <p>{`Je krijgt van ons uitsluitend een niet-exclusieve, niet overdraagbare, herroepelijke en niet-sublicentieerbare gebruikslicentie om Onze Diensten te gebruiken gedurende en in het kader van de met ons gesloten overeenkomst waarop Onze Voorwaarden van toepassing zijn. De App is bijvoorbeeld niet van jou, maar je mag hem wel gebruiken. Jouw gebruikerslicentie en alle overige rechten uit die overeenkomst vervallen als je geen overeenkomst (meer) met ons hebt.`}</p>
    <p>{`Wij worden geen eigenaar van informatie die jij post of aanbiedt via Onze Diensten, maar je geeft ons kosteloos een niet- exclusieve licentie om wereldwijd deze informatie te gebruiken, met inachtneming van onze Privacy Verklaring.`}</p>
    <h2>{`7. Beperking van aansprakelijkheid`}</h2>
    <p>{`Alle informatie, teksten en mogelijkheden die Onze Diensten bieden, zijn bedoeld als informatie en niet als financieel of professioneel advies. Het is je eigen verantwoordelijkheid om gedegen keuzes te maken omtrent je financiële situatie en waar nodig deskundigen in te schakelen.`}</p>
    <p>{`Je beseft dat aan het gebruik van Onze Diensten financiële risico’s verbonden kunnen zijn. Door Onze Diensten te gebruiken onderken je deze risico’s en aanvaard je deze. Het is bijvoorbeeld mogelijk dat je de App een verkeerde opdracht geeft waardoor veel geld op de verkeerde rekening terecht komt. Wij helpen jou je rekeningen te beheren en automatiseren, maar zijn nimmer verantwoordelijk voor de geldstromen of activiteiten op je rekening(en). Ook zijn wij niet aansprakelijk voor schade of verlies als gevolg van (geldstromen door) storingen, datalekken, mogelijke gebreken of andere onvolkomenheden waaronder mede, doch niet uitsluitend, begrepen defecten, malware, misbruik van de Software door derden en/of het verloren gaan van (persoons)gegevens. Het gebruik van Onze Diensten is geheel voor eigen risico. De gebruikslicentie komt zonder enige vorm van garantie, hoewel wij natuurlijk ons best doen om onze systemen zo goed mogelijk te beveiligen.`}</p>
    <p>{`Wij zijn sowieso niet aansprakelijk voor schade die (mede) het gevolg is van handelen of nalaten van derde partijen (bijvoorbeeld banken) of van jou zelf. Van dit laatste is in ieder geval sprake indien je één van de bepalingen van deze Gebruiksvoorwaarden overtreedt of hebt overtreden.`}</p>
    <h2>{`8. Overige bepalingen`}</h2>
    <p>{`Je kunt geen rechten uit onze samenwerking overdragen aan een derde.`}</p>
    <p>{`Indien een bepaling van deze Gebruiksvoorwaarden nietig dan wel onverbindend mocht blijken te zijn, blijven Flow en jij gebonden aan de overige bepalingen van deze Gebruiksvoorwaarden. Partijen zullen de nietige dan wel onverbindende bepaling(en) vervangen door een bepaling die wel verbindend is en waarvan de strekking zoveel mogelijk dezelfde is als die van de te vervangen bepaling(en), rekening houdend met het oogmerk van deze Gebruiksvoorwaarden.`}</p>
    <p>{`Op deze Gebruiksvoorwaarden, in het bijzonder de beperking van aansprakelijkheid, kunnen zich mede beroepen al onze werknemers, gelieerde partijen, bestuurders en aandeelhouders.`}</p>
    <h2>{`9. Recht, klachten en procedures`}</h2>
    <p>{`Op onze overeenkomst en deze Gebruiksvoorwaarden is Nederlands recht van toepassing.`}</p>
    <p>{`Wij hopen dat je altijd tevreden over ons bent. Mocht je toch iets dwarszitten, dan ben je verplicht dat eerst aan ons te melden (dat voorkomt onnodige rechtszaken). Je kunt jouw klacht of probleem mailen naar `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money."
      }}>{`hi@flowyour.money.`}</a>{` Wij hebben vanaf dat moment 14 dagen de tijd om de lucht te klaren, het probleem aan te pakken, of bloemen 💐 te sturen.`}</p>
    <p>{`Zijn wij er daarna nog niet uit, dan staat het je natuurlijk vrij om naar de rechter te gaan. Alle al dan niet contractuele geschillen tussen jou en ons zijn exclusief onderworpen aan de bevoegde rechter in Amsterdam.`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      